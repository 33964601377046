/**
 * Render a google places autocomplete field.
 * Supported props: name value format countries theme onChange onValidate focusRef
 */
import React, { useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { css } from '../util/pagetools';
import extractAddressFields from '../util/placetools';

// Workaround for current Google Autocomplete
if (typeof window !== 'undefined') { (window._gmcb = () => { }); }

export default function PlaceField(props) {
  const fieldName = (props.name || 'place_autocomplete');
  const COUNTRY_LOOKUPS = (Array.isArray(props.countries) ? props.countries : ['us']);
  const settings = { componentRestrictions: { country: COUNTRY_LOOKUPS }};
  if (props.format && props.format!=='cities') settings.types = [props.format];
  const fieldRef = props.focusRef || useRef(null);

  function onPlaceChange(place, inputRef) {
    const rc = (place.geometry ? extractAddressFields(place) : null);
    if (!rc) inputRef.value = '';
    if (props.onChange) props.onChange(rc);
  }

  const compOptions = {
    name: fieldName,
    id: fieldName,
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    options: settings,
    onPlaceSelected: onPlaceChange,
    ref: fieldRef,
    className: css('form-control', props.theme),
    placeholder: 'Enter an address',
    'aria-required': true
  };
  if (props.onValidate) {
    compOptions.onChange = props.onValidate;
    compOptions.onBlur = props.onValidate;
  }
  if (props.value) compOptions.defaultValue = props.value;

  return <Autocomplete {...compOptions} />;
};

/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';
import Safe from './Safe';

export default function Banner(props) {
  const cfg = props.src;

  return (
    <div id="banner" className={css('flx-v-sm', cfg.banner.theme)}>
      <div className="flx-half banner-left-content">
        <Safe className="banner-title" type="h1" content={cfg.title} />
        <Safe className="banner-text" type="p" content={cfg.subtitle} />
        <Link to="#screener" className={css('std-btn w-200 rounded-10', cfg.banner.btntheme)}>{cfg.startstudy}</Link>
      </div>
      <div className="flx-half">
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="vis-desktop" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="vis-mobile" />
      </div>
    </div>
  );
};

/**
 * A custom hook containing field validation functions.
 */

import { useState, useRef } from 'react';
import validator from '../util/validationManager';

export default function useValidation(datasource, doLog) {
  const [errors, setErrors] = useState({});
  const [formInteracted, setFormInteracted] = useState(false);
  const vm = validator();

  function addValidation(fieldname, rule, focusRef) {
    if (!focusRef) focusRef = useRef(null);
    vm.addValidation(fieldname, rule, focusRef);
  }

  function setNonBlank(fieldname, focusRef) {
    if (!focusRef) focusRef = useRef(null);
    vm.setNonBlank(fieldname, focusRef);
  }

  function getRef(fieldname) { return vm.getRef(fieldname); }

  function getRule(fieldname) { return vm.getRule(fieldname); }

  function getFields() { return vm.fields(); }

  function validateField(fieldname, fieldvalue) {
    const validator = vm.getRule(fieldname);
    const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
    setErrors({...errors, [fieldname]: !isFieldOk});
    // console.log(`Setting ${fieldname} error status to ${!isFieldOk}`);
    return isFieldOk;
  }

  function findErrors(fieldData) {
    if (!fieldData) fieldData = datasource;
    // Collect an array of field names and their statuses
    const updatedErrors = vm.fields().reduce((status, field) => {
      const fieldvalue = fieldData[field] || '';
      const validator = vm.getRule(field);
      const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
      if (doLog) console.log(`VF: ${field} = "${fieldvalue}" ..`, isFieldOk, (typeof fieldData[field]));
      status[field] = !isFieldOk;
      return status;
    }, {});
    setErrors(updatedErrors);
    // Return only those items with errors
    return Object.keys(updatedErrors).filter(key => updatedErrors[key]);
  }

  function validateAll(fieldData) {
    const invalidFields = findErrors(fieldData);
    if (invalidFields.length>0) {
      if (doLog) console.log('Errors found:', invalidFields);
      focusField(invalidFields[0]);
      return false;
    }
    return true;
  }

  function focusField(fieldname) {
    const fieldref = vm.getRef(fieldname).current;
    if (!fieldref) return;
    else if (fieldref.focus) fieldref.focus();
    else if (fieldref.getInputDOMNode) fieldref.getInputDOMNode().focus();
    else {
      const elem = document.getElementById(fieldname);
      elem.focus();
    }
  }

  function setError(fieldname, value) { setErrors({...errors, [fieldname]: value}); }

  function getError(fieldname, noStrict) {
    const hasError = errors[fieldname];
    if (noStrict) return (hasError===undefined ? true : hasError);
    return hasError && formInteracted;
  }

  function setAllErrors(settings) { setErrors(settings); }

  function getErrorFields() { return Object.keys(errors).filter(key => errors[key]); }

  return {
    setError, getError, addValidation, setNonBlank, getRef, getRule, findErrors,
    validateAll, getFields, getErrorFields, validateField, setAllErrors, setFormInteracted
  };
};

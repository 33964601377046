/**
 * Helper functions to parse address data from Google Places API.
 */
export default function extractAddressFields(place) {
  const lookup = addressParser(place);
  const payload = {
    full: place.formatted_address,
    Address: `${lookup('street_number')} ${lookup('route')}`,
    city: lookup('locality'),
    state: lookup('administrative_area_level_1', true),
    zip: lookup('postal_code'),
    country: lookup('country', true),
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng()
  };
  const premise = lookup('premise');
  if (premise) payload.premise = premise;
  return payload;
}

function addressParser(place) {
  return function(part, useShortname) {
    const addressPart = place.address_components.find(comp => comp.types.includes(part));
    return (addressPart ? (useShortname ? addressPart.short_name : addressPart.long_name) : '');
  };
}

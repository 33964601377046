/**
 * screenerNav implements navigation logic for the slides of a screener.
 * It adds a function to each question in a given configuration object
 * which implements logic for proceeding, that is, a function which
 * tells which response to a given question is the correct one.
 */

/**
 * Make a copy of a screener configuration, adding a function for each question.
 * @param object - a screener configuration object
 * @return object
 */
export default function addNavigation(config) {
  const newConfig = Object.assign({}, config);
  if (!Array.isArray(newConfig.questions)) newConfig.questions = [];

  // Add indexes for "post-question" slides: success, location picker, fail
  const qCount = newConfig.questions.length;
  newConfig.lastQuestionIndex = qCount-1;
  newConfig.successPageIndex = qCount;
  newConfig.locationsIndex = qCount+1;
  newConfig.failPageIndex = qCount+2;

  // Add a "proceed" function and other attributes to each question
  newConfig.questions = newConfig.questions.map((q, index) => ({
    ...q,
    seq: index,
    id: index,
    label:makeQuestionLabel(index+1, newConfig.questionLabel, qCount),
    proceed:getFn(q.proceedIfYes)
  }));
  return newConfig;
};

/**
 * Generate a counter label for a qiven question.
 * @param string - a configured alias to replace default numeric index
 * @param object - an object containing localized words
 * @param int - a number representing total questions
 * @return string
 */
function makeQuestionLabel(alias, labels, count) { return `${labels.noun} ${alias} ${labels.prep} ${count}`; }

/**
 * Return logic (another function) determining correctness of question response.
 * @param boolean - a configuration setting indicating whether or not Yes=Correct
 * @return function
 */
function getFn(proceedIfYes) {
  if (proceedIfYes===null) return function(reply) {return true};
  return (proceedIfYes ? fnYesGood : fnNoGood);
}

/**
 * Implement Yes=Correct logic.
 * @param boolean - a user response to a question
 * @return boolean - whether or not response was correct
 */
function fnYesGood(reply) { return Boolean(reply); }

/**
 * Implement No=Correct logic.
 * @param boolean - a user response to a question
 * @return boolean - whether or not response was correct
 */
function fnNoGood(reply) { return !Boolean(reply); }


/**
 * Utility for constructing form fields.
 * Currently DatePicker is disabled.
 */
import React from 'react';
import useValidation from '../components/useValidation';
import PhoneField from '../components/PhoneField';
import SelectBox from '../components/SelectBox';
import PlaceField from '../components/PlaceField';
import RadioList from '../components/RadioList';
import Checkbox from '../components/Checkbox';
// import DatePicker from '../components/DatePicker';
import { css } from './pagetools';

const REQUIRED = <span className="text-red" title="This field is required.">*</span>;

export default function init(fieldMetadata, formData, setter, addressData, addressSetter) {
  const vm = useValidation(formData, true);
  const elems = makeElements();
  const addressField = fieldMetadata.find(field => field.type==='address');

  /**
   * Convenience function to render a text input field.
   * @param object - a configuration object for a field
   * @param function - a function to be assigned to onChange and onBlur
   * @return JSX
   */
  function makeInput(field, onChangeBlur, validationRule) {
    if (!field.id) return <div>Field is not defined</div>;
    const fn = onChangeBlur || validateInput;
    if (field.validation) {
      if (validationRule) vm.addValidation(field.id, validationRule);
      else                vm.setNonBlank(field.id);
      return (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <input type="text" name={field.id} id={field.id} className="form-control" aria-required="true"
                 onChange={fn} onBlur={fn} value={formData[field.id]||''} ref={vm.getRef(field.id)} />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    }
    return (
      <div className="form-group-2">
        <label htmlFor={field.id}>{field.label}</label>
        <input type="text" name={field.id} id={field.id} className="form-control" aria-required="true"
               onChange={fn} onBlur={fn} value={formData[field.id]||''} />
      </div>
    );
  }

  function makePhoneInput(field, onChangeBlur) {
    if (!field.id) return <div>Field is not defined</div>;
    const fn = onChangeBlur || validateInput;
    vm.addValidation(field.id, /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
    return (
      <div className="form-group-2">
        <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
        <PhoneField name={field.id} onChange={fn} value={formData[field.id]||''} ref={vm.getRef(field.id)} />
        <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
      </div>
    );
  }

  function makeSelect(field, onChange) {
    if (!field.id) return <div>Field is not defined</div>;
    if (field.validation) vm.setNonBlank(field.id);
    return (
      <div className="form-group-2">
        {field.label &&
          <label htmlFor={field.id}>{field.label} {field.validation ? REQUIRED : null}</label>
        }
        <SelectBox name={field.id} id={field.id}
          items={field.choices || field.options}
          defaultItem={field.default}
          onChangeBlur={onChange}
          focusRef={vm.getRef(field.id)}
          errorFn={vm.getError}
          errorMessage={field.validation}
          theme={field.theme} />
      </div>
    );
  }

  function makeAddressInput(field, setter) {
    if (!field.id) return <div>Field is not defined</div>;
    function onChangeBlur(e) { if (e) setter(e) };
    // Only validates the text input string value, not valid object from API call
    function validatePlaceInput(event) {
      const isFieldOk = vm.getRule(field.id).test(event.target.value);
      vm.setError(event.target.name, !isFieldOk);
      if (!isFieldOk) setter({});
    }
    vm.setNonBlank(field.id);
    return (
      <div className="form-group-2">
        <label htmlFor={field.id}>{field.label} {field.validation ? REQUIRED : null}</label>
        <PlaceField name={field.id}
                    onChange={onChangeBlur}
                    onValidate={validatePlaceInput}
                    format="address"
                    focusRef={vm.getRef(field.id)} />
        <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
      </div>
    );
  }

  function makeRadioButtons(field) {
    if (!field.id) return <div>Radio buttons field is not defined</div>;
    if (field.validation) {
      vm.setNonBlank(field.id);
      return (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <RadioList className="flx" category={field.id} items={field.choices} onClick={validateInput} theme="mx-10" />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    }
    return (
      <div className="form-group-2">
        <label htmlFor={field.id}>{field.label}</label>
        <RadioList className="flx" category={field.id} items={field.choices} onClick={saveInput} theme="mx-10" />
      </div>
    );
  }

  function makeCheckbox(field) {
    if (!field.id) return <div>Field is not defined</div>;
    function makeCheckboxHandler() {
      if (field.validation) {
        return function(setting) { setter({...formData, [field.id]: setting}); vm.validateField(field.id, setting); };
      }
      return function(setting) { setter({...formData, [field.id]: setting}); };
    }
    const fn = makeCheckboxHandler();
    if (field.validation) {
      vm.setNonBlank(field.id);
      return (
        <div className="form-group-2">
          <Checkbox name={field.id}
            label={field.label}
            onChange={fn}
            focusRef={vm.getRef(field.id)}
            errorFn={vm.getError}
            errorMessage={field.validation} />
        </div>
      );
    }
    return (
      <div className="form-group-2">
        <Checkbox name={field.id} label={field.label} onChange={fn} />
      </div>
    );
  }

  /*
  function makeDateField(field) {
    if (!field.id) return <div>Field is not defined</div>;
    function onDateBlur(event) {
      const fieldvalue = (formData[event.target.id] ? formData[event.target.id].toLocaleDateString() : '');
      vm.validateField(event.target.id, fieldvalue);
    }
    function makeDateHandler() {
      return function(event) {
        const fieldvalue = new Date(event).toLocaleDateString();
        setter({...formData, [field.id]: event});
        if (field.validation) {
          const validator = vm.getRule(field.id);
          const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
          vm.setError(field.id, !isFieldOk);
        }
      };
    }
    const handler = makeDateHandler();
    if (field.validation) {
      vm.setNonBlank(field.id);
      return (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <DatePicker name={field.id} value={formData[field.id]} onChange={handler} onBlur={onDateBlur} theme="form-control" />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    }
    return (
      <div className="form-group-2">
        <label htmlFor={field.id}>{field.label}</label>
        <DatePicker name={field.id} value={formData[field.id]} onChange={handler} theme="form-control" />
      </div>
    );
  }
  */

  function validateForm() {
    vm.setFormInteracted(true);
    const fieldRecorder = (addressField ?
      function(field) { return (field===addressField.id ? (addressData.full || '') : formData[field]); } :
      function(field) { return formData[field]; }
    );
    // Collect an array of field names and their statuses
    const updatedErrors = vm.getFields().reduce((status, field) => {
      const fieldvalue = fieldRecorder(field);
      const validator = vm.getRule(field);
      const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
      // console.log(`VF: ${field} = "${fieldvalue}" ..`, isFieldOk);
      status[field] = !isFieldOk;
      return status;
    }, {});
    vm.setAllErrors(updatedErrors);
    const inValidFields = Object.keys(updatedErrors).filter(key => updatedErrors[key]);
    if (inValidFields.length===0) return true;
    const firstError = vm.getRef(inValidFields[0]).current; // Focus on first invalid field
    if (!firstError) return false;
    else if (firstError.focus) firstError.focus();
    else if (firstError.getInputDOMNode) firstError.getInputDOMNode().focus();
    return false;
  }

  function validateInput(event) {
    const fieldvalue = event.target.value;
    setter({...formData, [event.target.name]: fieldvalue});
    const validator = vm.getRule(event.target.name);
    if (!validator) return;
    const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
    vm.setError(event.target.name, !isFieldOk);
  }


  function saveInput(event) { setter({...formData, [event.target.name]: event.target.value}); }

  function input(field, onChangeBlur, isFullWidth) {
    elems.add(makeInput(field, onChangeBlur), isFullWidth);
  }

  function phone(field, isFullWidth) {
    elems.add(makePhoneInput(field), isFullWidth);
  }

  function email(field, onChangeBlur, isFullWidth) {
    elems.add(makeInput(field, onChangeBlur, /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/), isFullWidth);
  }

  function select(field, isFullWidth) {
    elems.add(makeSelect(field, (field.validation ? validateInput : saveInput)), isFullWidth);
  }

  function address(field, isFullWidth) {
    elems.add(makeAddressInput(field, addressSetter), isFullWidth);
  }

  function radio(field, isFullWidth) {
    elems.add(makeRadioButtons(field), isFullWidth);
  }

  function checkbox(field, isFullWidth) {
    elems.add(makeCheckbox(field), isFullWidth);
  }

  // function date(field, isFullWidth) { elems.add(makeDateField(field), isFullWidth); }

  function render() {
    if (!Array.isArray(fieldMetadata)) {
      return <div>Fields not properly configured. Does configuration file have an array property called <code>formfields</code>?</div>;
    }
    fieldMetadata.forEach(field => {
      const fieldType = field.type || 'input';
      if (fieldType==='address') address(field, field.fullWidth);
      else if (fieldType==='checkbox') checkbox(field, field.fullWidth);
      // else if (fieldType==='date') date(field, field.fullWidth);
      else if (fieldType==='email') email(field, field.fullWidth);
      else if (fieldType==='phone') phone(field, field.fullWidth);
      else if (fieldType==='radio') radio(field, field.fullWidth);
      else if (fieldType==='select') select(field, field.fullWidth);
      else input(field, field.fullWidth);
    });
    return elems.render();
  }

  function makeElements() {
    const collection = [];
    function add(content, isFullWidth) { collection.push({content, isFullWidth}); }
    function render() {
      return collection.map((item, index) => (<div key={`f-${index}`} className={css('p-grid', item.isFullWidth?'form-group-fullw':'')}>{item.content}</div>));
    }
    return {add, render};
  }

  return {validateForm, render};
}
